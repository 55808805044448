import { PureComponent, useEffect } from "react";
import Immutable from "immutable";
import classnames from "classnames";
import { connect } from "react-redux";
import { Button } from "@mui/material";
import { Dialog } from "@mui/material";
import { DialogTitle } from "@mui/material";
import { DialogContent } from "@mui/material";
import { Modal } from "@mui/material";

import * as EventActions from "../../../actions/event";
import * as NotificationActions from "../../../actions/notification";
import * as JudgeActions from "../../../actions/judge";

import { T } from "../../util/t";
import { JudgeSheet } from "./judgeSheet";
import { Icon as LHIcon } from "../../../styleguide/icons/Icon";
import { JudgeCardTableSkeleton } from "./judgeCard";
import { EmptyState } from "../../layout/EmptyState";
import { HeadJudgeEmpty } from "../../../styleguide/icons/icons";
import { useSubscribeToChannel } from "hooks/useSubscribeToChannel";
import { useBindToChannelEvent } from "../../providers/Realtime/RealtimeProvider";
import { useRealtimePresence } from "../../providers/Realtime/useRealtimePresence";
import { ArrowDropDown } from "@mui/icons-material";

const JudgePageSkeleton = () =>
    <div className="judge-sheet skeleton">
        <div className="heat-header">&nbsp;</div>

        <div className="toolbar"><div className="timer-wrapper">&nbsp;</div></div>

        <JudgeCardTableSkeleton/>
    </div>;

export const JudgePage = connect(({ events, judges }, { match: { params: { id } } }) => ({
    event: events.getIn(["mini", parseInt(id)], Immutable.Map()),
    heats: judges.getIn(["current_heat_ids", id], Immutable.List()).map(heatId => judges.getIn(["current_heats", id, heatId])),
    heatsLoaded: judges.getIn(["current_heat_ids", id])
}))(({ event, heats, heatsLoaded, currentUser, history, dispatch, match: { params: { id } } }) => {
    useEffect(() => {
        dispatch(NotificationActions.connecting());
        dispatch(JudgeActions.getCurrentHeats(id));
        dispatch(EventActions.get(id))
            .catch(e => {
                if (e.response && e.response.status === 404) {
                    dispatch(NotificationActions.warn("It looks like we can't find what you are looking for! Perhaps you were looking for one of our organisations?"));
                    return history.replace("/organisations");
                }
                throw e;
            });
    }, []);

    const { connection } = useRealtimePresence(`event-presence:${id}`);

    useEffect(() => {
        if (connection === "connected") dispatch(NotificationActions.connected());
        else if (connection === "unavailable") dispatch(NotificationActions.disconnected());
        else if (connection === "connecting") dispatch(NotificationActions.connecting());
    }, [connection]);

    const eventChannel = useSubscribeToChannel(`scores-${id}`);
    useBindToChannelEvent(eventChannel, "event-updated", () => dispatch(EventActions.get(id)));
    useBindToChannelEvent(eventChannel, "heat-updated", ({ id: heatId }) => dispatch(JudgeActions.getHeat(id, heatId)));
    useBindToChannelEvent(eventChannel, "current-heats-changed", () => dispatch(JudgeActions.getCurrentHeats(id)));
    useBindToChannelEvent(eventChannel, "schedule-updated", () => dispatch(JudgeActions.getCurrentHeats(id)));

    return (!event.isEmpty() && heatsLoaded) ?
        <JudgeSheetSelector
            heats={heats}
            judgeId={currentUser.get("id")}
            hideNames={event.get("hide_names_from_judges")}
            eventId={id}
        />
        :
        <JudgePageSkeleton/>;
});

export class JudgeSheetSelector extends PureComponent {
    state = {};

    selectHeat = (i) => this.setState({ selectedHeat: i });
    selectCategory = category => this.setState({ selectedCategory: category });
    openDialog = () => this.setState({ selectedHeat: undefined, selectedCategory: undefined });

    componentDidUpdate = (prevProps) => {
        if (prevProps.heats !== this.props.heats) {
            const newState = {}, prevHeats = prevProps.heats.filter(h => h), heats = this.props.heats.filter(h => h);
            if (prevHeats.size !== heats.size && this.props.heats.get(this.state.selectedHeat))
                newState.selectedHeat = undefined;

            if (!Immutable.is(prevHeats.map(h => h.getIn(["config", "categories"])), heats.filter(h => h).map(h => h.getIn(["config", "categories"]))))
                newState.selectedCategory = undefined;

            (newState.selectedHeat === undefined || newState.selectedCategory === undefined) && this.setState(newState);
        }
    };

    render = () => {
        const { heats, ...rest } = this.props,
            { selectedHeat, selectedCategory } = this.state,
            otherHeatIndex = selectedHeat === 0 ? 1 : 0,
            heatsSize = heats.filter(h => h).size,
            heat = (heatsSize > 1 || selectedHeat) ?
                heats.get(selectedHeat) :
                heats.first(),
            allowsJudging = (heat || Immutable.Map()).getIn(["config", "calculator"]) !== "placing",
            categories = (heat || Immutable.Map()).getIn(["config", "categories"]),
            allowSelectCategories = !(heat || Immutable.Map()).getIn(["config", "judge_categories_total"]);

        return <>
            <Dialog className="judge-dialog"
                    open={(!heat && heatsSize > 1 && !selectedHeat) || (allowSelectCategories && !!categories && !selectedCategory)}
                    aria-labelledby="judge-dialog-title">
                <SelectionDialogContent heat={heat} heatsSize={heatsSize} categories={categories} selectedHeat={selectedHeat} heats={heats} onSelectHeat={this.selectHeat} onSelectCategory={this.selectCategory}/>
            </Dialog>

            <Modal className="lh-modal judge-sheet-overlay" open={!heat && !!selectedHeat && !!heatsSize}>
                <table><tbody>
                    <tr><td>
                        <h1><T>There are no heats in your selected podium.</T></h1>
                        <br/>
                        <Button variant="contained" onClick={this.selectHeat.bind(this, otherHeatIndex)}>
                            <T selectedHeat={otherHeatIndex} heat={heats.get(otherHeatIndex)}>TakeMeTo_JudgeSheet_heatTitle</T>
                        </Button>
                    </td></tr>
                </tbody></table>
            </Modal>

            <Modal className="lh-modal judge-sheet-overlay" open={!heatsSize}>
                <table><tbody><tr><td>
                    <h1><T>That's a wrap! All heats are finished.</T></h1>
                    <LHIcon icon="Clap" title="That's a wrap"/>
                </td></tr></tbody></table>
            </Modal>

            {heat &&
            <div className="judge-sheet">
                <div className="heat-header" onClick={this.openDialog}>
                    <T selectedHeat={selectedHeat} selectedCategory={selectedCategory} heat={heat}>JudgeSheet_heatTitle</T>
                    {(heatsSize > 1 || (allowSelectCategories && categories)) && <ArrowDropDown />}
                </div>

                {(!allowSelectCategories || !categories || selectedCategory) &&
                (allowsJudging ?
                    <JudgeSheet heat={heat} category={selectedCategory} {...rest}/> :
                    <EmptyState icon={<HeadJudgeEmpty/>} text="You're off the hook! The current heat is configured to use placing, not scores."/>
                )}
            </div>}
        </>;
    };
}

const SelectionDialogContent = ({ heatsSize, heats, selectedHeat, heat, categories, onSelectHeat, onSelectCategory }) =>
    <>
        <DialogTitle id="judge-dialog-title"><T>What are you judging?</T></DialogTitle>
        <DialogContent>
            {heatsSize > 1 &&
            <>
                <p><T>Select podium</T></p>
                {heats.map((heat, i) =>
                    <Button key={heat.get("id")} className={classnames(selectedHeat === i && "selected")} variant="outlined" onClick={() => onSelectHeat(i)}>
                        <T selectedHeat={i} heat={heat}>JudgeSheet_heatTitle</T>
                    </Button>)}
            </>}
            {heat && categories &&
            <>
                <p><T>Select category</T></p>
                {categories.keySeq().map(category =>
                    <Button key={category} variant="outlined" onClick={() => onSelectCategory(category)}>
                        {category}
                    </Button>)}
            </>}
        </DialogContent>
    </>;
