import { Dialog, DialogTitle, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { Bookmarks } from "@mui/icons-material";
import { DialogFormKit } from "../../forms/FormKit";
import { useEventDivision } from "../../routes/events/id/divisions/eventDivisionId/EventDivisionProvider";
import { useDialogState } from "../../../hooks/useDialogState";
import { string } from "yup";
import { useMutation } from "@apollo/client";
import { useError, useSuccess } from "../../../hooks/useNotifications";
import { T } from "../../util/t";
import { useOrganisation } from "../../providers/OrganisationProvider";
import { SAVE_AS_TEMPLATE } from "../../../graphql/eventDivision";
import { useLocalizationContext } from "../../providers/LocalizationProvider";

export const SaveAsTemplateWidget = ({ closeMenu }) => {
    const [dialogOpen, openDialog, closeDialog] = useDialogState();
    const onCompleted = () => {
        closeDialog();
        closeMenu();
    };

    return (
        <>
            <ListItem button onClick={openDialog}>
                <ListItemIcon>
                    <Bookmarks/>
                </ListItemIcon>
                <ListItemText primary={<T>Save format</T>}/>
            </ListItem>

            <Dialog open={dialogOpen} aria-labelledby="save-as-template-dialog-title" onClose={closeDialog}>
                <DialogTitle id="save-as-template-dialog-title"><T>Save format as new division template</T></DialogTitle>
                <SaveAsTemplateForm onCompleted={onCompleted} closeDialog={closeDialog}/>
            </Dialog>
        </>
    );
};

export const SaveAsTemplateForm = ({ onCompleted, closeDialog }) => {
    const eventDivision = useEventDivision();
    const { id: organisationId } = useOrganisation();
    const [saveAsTemplate] = useMutation(SAVE_AS_TEMPLATE);
    const { notifySuccess } = useSuccess();
    const { notifyResponseError } = useError();
    const { translate } = useLocalizationContext();
    const placeholder = translate("custom_template_name", { name: translate(eventDivision.template.name) });

    const onSubmit = variables => saveAsTemplate({
        variables,
        onCompleted: () => {
            onCompleted();
            notifySuccess("Template created for future use, and set for this division ✅");
        },
        onError: notifyResponseError
    });

    return (
        <DialogFormKit
            fields={[{
                name: "name",
                type: "text",
                label: "Template name",
                validation: string().required("The template needs a name"),
                inputProps: { placeholder }
            }]}
            initialValues={{
                eventDivisionId: eventDivision.id,
                organisationId
            }}
            onSubmit={onSubmit}
            onCancel={closeDialog}
            buttonLabel="Create template"
        >
            <Typography marginBottom={2}><T>all_saved_on_save_as_template</T></Typography>
        </DialogFormKit>
    );
};
