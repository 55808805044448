import { useLazyQuery } from "@apollo/client";
import { useEffect, useRef } from "react";

export const useCancellableLazyQuery = (query, options, fireOnMount) => {
    const abortControllerRef = useRef();
    const [lazyQuery, result] = useLazyQuery(query, options);

    useEffect(() => {
        fireOnMount && lazyQuery();
        return () => abortControllerRef.current?.abort();
    }, []);

    const cancellableLazyQuery = options => {
        abortControllerRef.current?.abort();
        abortControllerRef.current = typeof AbortController === "undefined" ? AbortController : new AbortController();
        lazyQuery({ ...options, context: { queryDeduplication: false, fetchOptions: { signal: abortControllerRef.current?.signal } } });
    };

    return [cancellableLazyQuery, result];
};
