import PropTypes from "prop-types";
import { ListItem } from "@mui/material";
import { ListItemIcon } from "@mui/material";
import { ListItemText } from "@mui/material";
import { LinearProgress } from "@mui/material";

import { AsyncAction } from "./asyncAction";

export class ActionListItem extends AsyncAction {
    static propTypes = {
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
        action: PropTypes.func.isRequired,
        className: PropTypes.string
    };

    render = ({ Icon, text, disabled: propsDisabled, submitting } = this.props, { disabled } = this.state) =>
        <ListItem button onClick={this.onClick} disabled={propsDisabled || disabled}>
            <ListItemIcon>
                {Icon && <Icon />}
            </ListItemIcon>
            <ListItemText primary={
                <span>
                    {text}
                    {(disabled || submitting) && <LinearProgress component="span"/>}
                </span>}/>
        </ListItem>;
}
