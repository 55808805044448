import { useRouteMatch } from "react-router-dom";
import { makeStyles } from "../../providers/makeStyles";
import { Stack, Typography } from "@mui/material";
import { T } from "../../util/t";
import { useIsMobile } from "../../../hooks/useIsMobile";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: theme.palette.brand.tertiary,
        background: theme.palette.brand.secondary,
    }
}));
export const SiteBroadcast = () => {
    const isHome = useRouteMatch("/");
    const classes = useStyles();
    const isMobile = useIsMobile();
    const direction = isMobile ? "column" : "row";
    const spacing = isMobile ? 0 : 0.5;

    if (!isHome.isExact) return null;

    return (
        <div className={classes.wrapper}>
            <Stack direction={direction} padding={1} spacing={spacing} alignItems="center">
                <Typography variant="brandLabel1"><T>Liveheats has a new look and feel.</T></Typography>
                <Typography variant="brandBody1"><T> Same platform, fresh vibe 🔥</T></Typography>
            </Stack>
        </div>
    );
};
