import Immutable from "immutable";
import { Field } from "redux-form/immutable";

import { checkboxInput, dateInput, plainInput, required, selectInput } from "../forms";

const validateIfNeeded = (value, values, props, name) => {
    const match = name.match(/athletes\[(\d+)]/);
    if (match) {
        const athleteIndex = match[1], athlete = values.getIn(["athletes", athleteIndex]);
        return athlete && !(athlete.get("id") && !athlete.get("registrations_attributes",  Immutable.List()).some(d => d && !d.get("id"))) &&
            required(value && (value.get("size") || value.get("value"))); // still need size for now while included extras are not part of general properties
    }

    return required(value);
};

const typeToInputType = {
    text: plainInput,
    signature: plainInput,
    select: selectInput,
    date: dateInput,
    checkbox: checkboxInput
};

const formatDefault = prop => prop ? prop.get("value") : "";
const formatCheckbox = prop => !!prop;
const typeToFormat = {
    text: formatDefault,
    signature: formatDefault,
    select: formatDefault,
    date: formatDefault,
    checkbox: formatCheckbox
};

export const CustomProperties = ({ captureFields, level, basePath }) =>
    (captureFields || []).filter(p => p.level === level).map(property => {
        const props = {
            name: `${basePath}.${property.uuid}`,
            component: typeToInputType[property.type],
            label: property.label,
            parse: value => value ? Immutable.Map({ uuid: property.uuid, label: property.label, value }) : null,
            format: typeToFormat[property.type],
        };
        if (property.type === "text" || property.type === "signature") props.type = "text";
        if (property.required) props.validate = validateIfNeeded;
        if (property.type === "select") {
            props.selectOptions = Immutable.List(property.options)
                .map(option => ({ label: option, value: option }))
                .unshift({ label: "", value: null });
        }

        return <Field key={property.uuid} {...props}/>;
    });
