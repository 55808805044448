import Immutable, { Map } from "immutable";
import snakeCase from "lodash/snakeCase";
import camelCase from "lodash/camelCase";
import transform from "lodash/transform";
import isObject from "lodash/isObject";
import isArray from "lodash/isArray";

export const translateText = ({ text, localization, props }) => {
    const textTranslations = localization.getIn(["translations", text]) || {},
        translatedText = textTranslations[localization.get("language")] || textTranslations["en"];

    return translatedText != null ? (typeof translatedText === "function" ? translatedText(props) : translatedText) : text;
};

export const formatPrice = (total, currency) => {
    const currencyToUse = (currency && currency.toUpperCase) ? currency.toUpperCase() : currency;
    const convertedTotal = Math.abs(total);
    const sign = total < 0 ? "-" : "";
    return {
        "AUD": `${sign}$${convertedTotal / 100} AUD`,
        "USD": `${sign}$${convertedTotal / 100} USD`,
        "CAD": `${sign}$${convertedTotal / 100} CAD`,
        "GBP": `${sign}£${convertedTotal / 100}`,
        "EUR": `${sign}€${convertedTotal / 100}`,
        "JPY": `${sign}¥${convertedTotal}`,
        "NOK": `${sign}kr ${convertedTotal / 100} NOK`,
        "DKK": `${sign}kr ${convertedTotal / 100} DKK`,
        "SEK": `${sign}${convertedTotal / 100}kr SEK`,
        "BRL": `${sign}R$${convertedTotal / 100}`,
        "ZAR": `${sign}R${convertedTotal / 100}`,
    }[currencyToUse] || `${total / 100} ${currencyToUse || "AUD"}`;
};

export const formatPriceSmall = (total, currency) => {
    const currencyToUse = currency.toUpperCase ? currency.toUpperCase() : currency;
    return {
        "GBP": `£${total / 100}`,
        "EUR": `€${total / 100}`,
        "JPY": `¥${total}`,
        "NOK": `kr ${total / 100}`,
        "DKK": `kr ${total / 100}`,
        "SEK": `${total / 100}kr`,
        "BRL": `R$${total / 100}`,
        "ZAR": `R${total / 100}`,
    }[currencyToUse] || `$${total / 100}`;
};

export const isNotNullOrUndefined = value => value != null;

export const nSpaces = (n) => Immutable.List().setSize(n).join("\xa0");

export const normalizeString = string => String.prototype.normalize ? string.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : string;

export const isTeamFormat = formatDefinition => Map.isMap(formatDefinition)
    ? !!formatDefinition?.get("is_teams")
    : !!formatDefinition?.isTeams;

export const nonWhitespacePattern = /\S+/g;
const searchSafePattern = word => word.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
export const SearchRegExp = word => new RegExp(searchSafePattern(word), "gi");
export const SearchRegExpCombined = words => new RegExp(words.map(searchSafePattern).join("."), "gi");

const hash = s => {
    /* Simple hash function. */
    let a = 1, c = 0, h, o;
    if (s) {
        a = 0;
        /*jshint plusplus:false bitwise:false*/
        for (h = s.length - 1; h >= 0; h--) {
            o = s.charCodeAt(h);
            // eslint-disable-next-line
            a = (a<<6&268435455) + o + (o<<14);
            c = a & 266338304;
            // eslint-disable-next-line
            a = c!==0?a^c>>21:a;
        }
    }
    return String(a);
};

export const getUniqueClassFromStringHash = (stringToHash, maxNumberId, prefix) => {
    const altHash = hash(stringToHash);
    const defaultColor = Math.round(altHash.split("").reduce((sum, n) => sum + parseInt(n), 0) / altHash.length * maxNumberId/9);
    return { [prefix + "-" + defaultColor]: true };
};

export const recursiveCamelToSnake = (excludeFields = []) => ([field, value]) =>
    [field === "__typename" ? field : snakeCase(field), excludeFields.indexOf(field) > -1
        ? value
        : Immutable.List.isList(value)
            ? value.map(v => Immutable.Map.isMap(v)
                ? v.mapEntries(recursiveCamelToSnake(excludeFields))
                : v)
            : Immutable.Map.isMap(value)
                ? value.mapEntries(recursiveCamelToSnake(excludeFields))
                : value
    ];

export const snakeToCamel = object => Object.keys(object).reduce((result, current) => ({ ...result, [camelCase(current)]: object[current] }), {});

export const recursiveSnakeToCamel = (obj, excludeFields = []) => transform(obj, (acc, value, key, target) => {
    const camelKey = (isArray(target) || excludeFields.indexOf(key) > -1) ? key : camelCase(key);
    acc[camelKey] = (isObject(value) && excludeFields.indexOf(key) === -1) ? recursiveSnakeToCamel(value, excludeFields) : value;
});

export const getQueryParams = location =>
    location.search.substring(1).split("&").reduce((allParams, param) => {
        const [key, value] = param.split("=");
        return { ...allParams, [decodeURIComponent(key)]: decodeURIComponent(value) };
    }, {});

export const uppercaseFirst = string => string.charAt(0).toUpperCase() + string.slice(1);
