import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";

import * as EventActions from "../../actions/event";
import * as HeatActions from "../../actions/heat";
import * as NotificationActions from "../../actions/notification";

import { EventProvider } from "../routes/events/id/EventProvider";
import { manageEventPagesPath } from "../application";
import { eventSelector } from "./selectors";
import { AdminPageContainer } from "components/layout/page/AdminPageContainer";
import { OrganisationProvider } from "../providers/OrganisationProvider";
import { useSubscribeToChannel } from "hooks/useSubscribeToChannel";
import { Event } from "models/Event";
import { convertToJS } from "../eventDivisions/helpers";
import { useBindToChannelEvent } from "../providers/Realtime/RealtimeProvider";

export const EventRoot = connect((state, props) => ({
    event: eventSelector(state, props),
}))(({ event, children, history, match }) => {
    const { params: { id } } = match;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(EventActions.getSchedule(id));
        dispatch(EventActions.get(id))
            .catch(e => {
                if (e.response && e.response.status === 404) {
                    dispatch(NotificationActions.warn("It looks like we can't find what you are looking for! Perhaps you were looking for one of our organisations?"));
                    return history.replace("/organisations");
                }
                throw e;
            });
    }, []);

    const eventChannel = useSubscribeToChannel(`scores-${id}`);
    useBindToChannelEvent(eventChannel, "event-updated", () => dispatch(EventActions.get(id)));
    useBindToChannelEvent(eventChannel, "heat-updated", ({ id: heatId }) => dispatch(HeatActions.get(id, heatId)));
    useBindToChannelEvent(eventChannel, "current-heats-changed", () => dispatch(EventActions.getCurrentHeats(id)));
    useBindToChannelEvent(eventChannel, "schedule-updated", () => {
        dispatch(EventActions.getSchedule(id));
        dispatch(EventActions.getCurrentHeats(id));
    });

    return (
        <EventProvider value={{ event, eventChannel, eventObject: !event.isEmpty() ? Event(convertToJS(event)) : undefined }}>
            <OrganisationProvider value={{ organisation: convertToJS(event.get("organisation")) }}>
                {useRouteMatch(manageEventPagesPath)
                    ? <AdminPageContainer>{children}</AdminPageContainer>
                    : <main>{children}</main>}
            </OrganisationProvider>
        </EventProvider>
    );
});
