import { useCurrentUser } from "../CurrentUserProvider";
import { useCallback, useContext, useEffect, useState } from "react";
import { RealtimeContext, useRealtimeChannel } from "./RealtimeProvider";
import { useRealtimeConnectionState } from "./useRealtimeConnectionState";

export const useRealtimePresence = (channelName) => {
    const { currentUser } = useCurrentUser();
    const { channel } = useRealtimeChannel(channelName);
    const [members, setMembers] = useState([]);
    const onPresenceUpdate = useCallback(() => {
        channel.presence.get((err, members) => {
            if (members) {
                const uniqueMembers = members
                    .filter(member => member.clientId.split("-")[0] !== currentUser.id.toString())
                    .map(member => ({
                        id: member.clientId.split("-")[0],
                        name: member.clientId.split("-")[1],
                        status: member.action === "present" ? "online" : "offline"
                    }));
                setMembers(uniqueMembers);
            }
        });
    }, [channel, setMembers]);
    useContext(RealtimeContext).usePresence?.(channelName, null, onPresenceUpdate);
    const connection = useRealtimeConnectionState(channelName);

    useEffect(() => () => {
        if (channel) {
            setTimeout(async () => {
                if ((channel.state === "attaching" || channel.state === "attached") && channel.presence.subscriptions.any.length === 0 && channel.presence.subscriptions.anyOnce.length === 0 && Object.keys(channel.presence.subscriptions.events).length === 0 && Object.keys(channel.presence.subscriptions.eventsOnce).length === 0) {
                    await channel.detach();
                }
            }, 2500);
        }
    }, [channel]);

    return { connection, members };
};
