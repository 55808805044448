import { Component, memo, useCallback, useEffect, useRef, useState } from "react";
import Immutable from "immutable";
import classnames from "classnames";
import dayjs from "dayjs";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Box, Button, IconButton } from "@mui/material";
import { getEmptyImage } from "react-dnd-html5-backend";
import { Add, DateRange, DragIndicator, Edit } from "@mui/icons-material";

import * as EventActions from "../../../actions/event";
import * as EventDirectorActions from "../../../actions/eventDirector";
import * as NotificationActions from "../../../actions/notification";

import { Heading } from "../../layout/page/Heading";
import { EditBreakDialog } from "./editBreakDialog";
import { CustomDragLayer } from "../../DnD/dragLayers";
import { T } from "../../util/t";
import { LHIconButton } from "../../actions/LHIconButton";
import { ShowPrinting } from "../../util/printing";
import { eventDate } from "../eventDate";
import { PrintButton } from "../../layout/print/PrintButton";
import { EmptyState } from "../../layout/EmptyState";
import { IconEmptySchedule } from "../../../styleguide/icons/icons";
import { LONG_DATE_AND_TIME } from "utils/dates";
import { HeaderLogo } from "../../layout/print/HeaderLogo";
import { DivisionPill } from "../../layout/division/DivisionPill";
import { createDivisionList } from "../../layout/schedule/useDivisionList";
import { useDrag, useDragDropManager, useDrop } from "react-dnd";
import { MultiDnD } from "../../providers/DnD/MultiDnD";
import { EventStatusPill } from "components/layout/event/EventStatusPill";

const HeatSkeleton = ({ drag }) =>
    <div key="heat-1" className="heat">
        {drag && <div className="drag-handle"><DragIndicator fontSize="inherit" /></div>}

        <div className="text">
            <DivisionPill indicator={"1"}><T>Division</T></DivisionPill>
            <div className="round-heat">
                <div className="round-name"><T>Round</T></div>
                <div className="heat-number"><T> : Ht</T></div>
            </div>
        </div>

        <span className="edit-icon">
            <IconButton className="tiny">
                <Edit />
            </IconButton>
        </span>
    </div>;

const ScheduleSkeletonRow = ({ drag, heat2 }) => [
    <div key="time" className="time"/>,

    <HeatSkeleton key="heat1" drag={drag}/>,

    heat2 ? <HeatSkeleton key="heat2" drag={drag}/> : <div key="empty" className="empty">&nbsp;</div>
];

const EditScheduleSkeleton = () =>
    <div className="skeleton">
        <Heading title="Schedule"/>

        <div className="podiums">
            <div className="time">&nbsp;</div>
            <div className="podium"><T>Main bank</T></div>
            <div className="podium"><T>Secondary bank</T></div>
        </div>

        <div className="break">
            <span className="text">{dayjs().format(LONG_DATE_AND_TIME)}</span>
            <span className="edit-icon">
                <LHIconButton className="tiny" title="Edit break">
                    <DateRange />
                </LHIconButton>
            </span>
        </div>

        <ScheduleSkeletonRow heat2/>
        <ScheduleSkeletonRow drag/>
        <ScheduleSkeletonRow drag heat2/>
        <ScheduleSkeletonRow drag/>
        <ScheduleSkeletonRow drag/>
        <ScheduleSkeletonRow drag/>
    </div>;

const useDragTarget = ({ type, endUpdate, dispatch, ref, heatsStarted, eventId, heatId, heatDivision, heatRound, heatNumber, heatType, position, podium, index, blockDate, divisionIndicator, getSelectedHeats, clearSelectedHeats }) => useDrag({
    type,
    item: () => {
        window.navigator.vibrate && window.navigator.vibrate(100);
        dispatch(EventDirectorActions.beginDrag());

        const source = { podium, position },
            selectedHeats = getSelectedHeats?.(source);

        if (podium) {
            dispatch(EventDirectorActions.scheduleShuffle({
                event_id: eventId,
                source: { ...source, selection: selectedHeats },
                target: source
            }));
        }

        return {
            ...source,
            eventId,
            blockDate,
            heatId,
            heatDivision,
            heatRound,
            heatNumber,
            heatType,
            index,
            divisionIndicator,
            selectedHeats,
            clearSelectedHeats,
            width: ref.current?.clientWidth,
            height: ref.current?.clientHeight
        };
    },
    end: (item, monitor) => {
        const didDrop = monitor.didDrop(), dropResult = monitor.getDropResult();

        if (!didDrop || !dropResult.dropped) {
            dispatch(EventDirectorActions.cancelDrag());
        } else {
            dispatch(EventDirectorActions.endDrag());
        }
        item.clearSelectedHeats?.();
        endUpdate();
    },
    canDrag: () => !heatsStarted,
    collect: monitor => ({
        canDrag: monitor.canDrag(),
        dragMonitorId: monitor.getHandlerId()
    })
});

export const DraggableRow = memo(({ eventId, index, blockDate, position, heatsStarted, endUpdate, doEditBreak, isBreak, children }) => {
    const dispatch = useDispatch();
    const ref = useRef();

    const [{ canDrag, dragMonitorId }, drag, dragPreview] = useDragTarget({
        type: "break",
        eventId,
        blockDate,
        position,
        index,
        dispatch,
        endUpdate,
        ref
    });

    useEffect(() => {
	    dragPreview(getEmptyImage(), { captureDraggingState: true });
    }, [dragPreview]);

    const onClick = () => isBreak && doEditBreak(heatsStarted, { date: blockDate, index });

    return (
        <div onClick={onClick} className={classnames({ break: isBreak, [`block${index + 1}`]: isBreak, "can-drag": canDrag && isBreak })} ref={ref} data-drag-monitor-id={dragMonitorId}>
            {!heatsStarted &&
            <div ref={drag} className={classnames("drag-handle", { "hide": !isBreak })}>
                <DragIndicator fontSize="inherit"/>
            </div>}

            {children}
        </div>
    );
});

export const BreakContent = ({ heatsStarted, blockDate }) => (
    <>
        <span className="text">{dayjs(blockDate).format(LONG_DATE_AND_TIME)}</span>

        {!heatsStarted &&
        <span className="edit-icon">
            <LHIconButton className="tiny" title="Edit break">
                <DateRange />
            </LHIconButton>
        </span>}
    </>
);

export const DragDropCell = memo(({ heatId, heatStartTime, heatEndTime, heatDivision, heatRound, heatNumber, heatType, heatsStarted, selectHeat, position, podium, endUpdate, eventId, divisionIndicator, getSelectedHeats, selected, clearSelectedHeats, scheduleUpdate }) => {
    const dispatch = useDispatch();
    const ref = useRef();

    const [{ canDrag, dragMonitorId }, drag, dragPreview] = useDragTarget({
        endUpdate,
        heatsStarted,
        eventId,
        heatId,
        heatDivision,
        heatRound,
        heatNumber,
        heatType,
        position,
        podium,
        divisionIndicator,
        getSelectedHeats,
        clearSelectedHeats,
        dispatch,
        type: "heat",
        ref
    });
    const [{ isOver, canDrop, dropMonitorId }, drop] = useDrop({
        accept: "heat",
        hover: (item, monitor) => {
            if (!monitor.canDrop()) return;
            if (item.heatId === heatId) return;

            const source = { position: item.position, podium: item.podium, selection: item.selectedHeats }, target = { position, podium };

            return scheduleUpdate(() => dispatch(EventDirectorActions.scheduleShuffle({ event_id: item.eventId, source, target })));
        },
        canDrop: () => !heatsStarted,
        drop: item => {
            if (item.position !== position || item.podium !== podium || item.selectedHeats.size > 1) {
                const source = { position: item.position, podium: item.podium, selection: item.selectedHeats },
                    target = { position, podium };
                dispatch(EventDirectorActions.endScheduleShuffle({ event_id: item.eventId, source, target }));
                dispatch(EventDirectorActions.saveSchedule({ event_id: item.eventId }));
            }
            return { dropped: true };
        },
        collect: monitor => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop(),
            dropMonitorId: monitor.getHandlerId()
        })
    });

    useEffect(() => {
	    dragPreview(getEmptyImage(), { captureDraggingState: true });
    }, [dragPreview]);

    const live = heatStartTime && !heatEndTime;
    const onClick = e => !heatsStarted && heatId && selectHeat({ podium, position }, e);

    return (
        <div className={classnames({ heat: !!heatId, live, selected, empty: !heatId, finished: !!heatEndTime, "can-drag": canDrag, isOver: canDrop && isOver, "cannot-drop": !canDrop })}
             onClick={onClick}
             ref={drop(ref)}
             data-drag-monitor-id={dragMonitorId}
             data-drop-monitor-id={dropMonitorId}
        >
            {!heatsStarted &&
            <div ref={drag} className={classnames("drag-handle", { "hide": !heatId })}>
                <DragIndicator fontSize="inherit"/>
            </div>}

            {heatId ?
                <HeatCellContent
                    heatId={heatId}
                    heatDivision={heatDivision}
                    heatRound={heatRound}
                    heatNumber={heatNumber}
                    heatType={heatType}
                    divisionIndicator={divisionIndicator}
                    live={live}
                /> :
                <EmptyCellContent/>
            }
        </div>
    );
});

export const HeatCellContent = ({ live, heatId, heatDivision, heatRound, heatNumber, heatType, divisionIndicator }) => (
    <>
        <div className="text">
            <DivisionPill indicator={divisionIndicator}>{heatDivision}</DivisionPill>
            <div className="round-heat">
                <div className="round-name"><T>{heatRound}</T></div>
                <div className="heat-number">
                    <T number={heatNumber}>
                        {heatType === "run" ? "run_short_title" : "heat_short_title"}
                    </T>
                </div>
            </div>
        </div>

        {live && <span className="live-tag"><EventStatusPill status="on"/></span>}

        <span className="edit-icon">
            <LHIconButton className="tiny" component={Link} to={`heats/${heatId}/edit`} title="Edit heat">
                <Edit />
            </LHIconButton>
        </span>
    </>
);

export const EmptyCellContent = () => <span>&nbsp;</span>;

const ScheduleLoaded = ({ eventId, event, podiums, scheduleSize, schedule, fullSchedule, breaks, podiumsSize, divisionsList, divisionsIds }) => {
    const [editing, setEditing] = useState(false);
    const [editBreak, setEditBreak] = useState(false);
    const [selectedHeats, setSelectedHeats] = useState(Immutable.List());
    const [initialSelection, setInitialSelection] = useState();
    const dispatch = useDispatch();

    const requestedFrame = useRef();
    const pendingUpdateFn = useRef();

    const drawFrame = () => {
        pendingUpdateFn.current();

        pendingUpdateFn.current = undefined;
        requestedFrame.current = undefined;
    };

    const scheduleUpdate = useCallback((updateFn) => {
        pendingUpdateFn.current = updateFn;

        if (!requestedFrame.current) {
            requestedFrame.current = requestAnimationFrame(drawFrame);
        }
    }, []);

    const endUpdate = useCallback(() => {
        cancelAnimationFrame(requestedFrame.current);
        requestedFrame.current = undefined;
    }, []);

    const selectHeat = useCallback((selection, { metaKey, shiftKey }) => {
        const safeInitialSelection = initialSelection || selection;
        if (!metaKey && !shiftKey) {
            setSelectedHeats(Immutable.List([selection]));
            setInitialSelection(selection);
        } else if (metaKey) {
            if (selectedHeats.find(s => s.podium === selection.podium && s.position === selection.position)) setSelectedHeats(selectedHeats.delete(selectedHeats.findIndex(s => s.podium === selection.podium && s.position === selection.position)));
            else setSelectedHeats(selectedHeats.push(selection), safeInitialSelection);
        } else if (shiftKey) {
            const selections = Immutable.List([(safeInitialSelection).position, selection.position]),
                firstPosition = selections.min(),
                lastPosition = selections.max();

            setSelectedHeats(fullSchedule.getIn(["podiums"]).mapEntries(([podium, heats]) =>
                (podium === (safeInitialSelection).podium || podium === selection.podium) && [podium,
                    heats.slice(firstPosition, lastPosition + 1).map((heat, i) => heat && { podium, position: firstPosition + i })]
            ).valueSeq().flatten().filter(s => s).toList());
            setInitialSelection((safeInitialSelection));
        }
    }, [selectedHeats, initialSelection]);

    const isHeatSelected = (selectedHeats, { podium, position }) => selectedHeats.find(s => s.podium === podium && s.position === position);

    const getSelectedHeats = useCallback((dragItem) => {
        return dragItem && !isHeatSelected(selectedHeats, { position: dragItem.position, podium: dragItem.podium }) ?
            Immutable.List( ) : selectedHeats;
    }, [selectedHeats]);

    const clearSelectedHeats = useCallback(() => {
        setSelectedHeats(Immutable.List());
        setInitialSelection(undefined);
    }, []);

    const addBreak = () => {
        const defaultDate = Immutable.List([dayjs().add(1, "hour").startOf("hour").format(), event.get("date")]).max();
        setEditing(true);
        setEditBreak({
            adding: true,
            initialValues: { date: defaultDate },
            afterSubmit: () => {
                dispatch(NotificationActions.success("You've successfully added a break to the schedule. You can now drag it into position."));
                const node = document.querySelector(`.block${fullSchedule.get("breaks").size + 1}`);
                node && node.scrollIntoView && node.scrollIntoView({ block: "center", inline: "nearest", behavior: "smooth" });
            }
        });
    };

    const doEditBreak = useCallback((heatsStarted, initialValues) => {
        if (!heatsStarted) {
            setEditing(true);
            setEditBreak({
                initialValues,
                afterSubmit: () => dispatch(NotificationActions.success("You're a schedule making superstar. Break successfully updated."))
            });
        };
    }, []);

    const breakRemoved = () => dispatch(NotificationActions.success("Who needed that break anyway? Break successfully removed from the schedule."));

    const closeEditBreak = () => setEditing(false);

    const scheduleRows = [...Array(scheduleSize)].reduce((rows, _, position) => {
        const heatsStarted = podiums.some(podium => schedule.getIn([podium, position, "start_time"])),
            scheduledTime = schedule.valueSeq().map(heats => heats.getIn([position, "scheduled_time"])).filter(t => t).first();
        breaks.forEach((b, breakIndex) => {
            if (b.get("position") === position) rows.push({ break: b, position, breakIndex, heatsStarted });
        });
        rows.push({ heatsStarted, scheduledTime, position, heats: podiums.map(podium => ({ podium, heat: schedule.getIn([podium, position]) })) });

        return rows;
    }, []);
    return (
        <>
            <EditBreakDialog open={editing} eventId={eventId} handleClose={closeEditBreak} afterDelete={breakRemoved} breaks={breaks} {...editBreak}/>

            <ShowPrinting>
                <HeaderLogo />
                {event.getIn(["organisation", "name"])} / {event.get("name")} / {eventDate({ date: event.get("date"), window: event.get("days_window") })}
            </ShowPrinting>

            <Heading title="Schedule" actions={
                <><Button variant="outlined" className="add-break" onClick={addBreak} startIcon={<Add/>}>
                    <T>Add Break</T>
                </Button>
                <PrintButton /></>
            }/>

            {podiums.size > 1 &&
            <div className="podiums">
                <div className="time">&nbsp;</div>
                {podiums.map(podium => <div key={podium} className="podium"><T>{podium}</T></div>)}
            </div>}

            {scheduleRows.map((row, i) =>
                <ScheduleRow
                    key={i}
                    row={row}
                    eventId={eventId}
                    doEditBreak={doEditBreak}
                    scheduleUpdate={scheduleUpdate}
                    endUpdate={endUpdate}
                    podiumsSize={podiumsSize}
                    divisionsList={divisionsList}
                    divisionsIds={divisionsIds}
                    selectedHeats={selectedHeats}
                    selectHeat={selectHeat}
                    getSelectedHeats={getSelectedHeats}
                    isHeatSelected={isHeatSelected}
                    clearSelectedHeats={clearSelectedHeats}
                />)}
        </>
    );
};

const DroppableRow = ({ position, heatsStarted, scheduleUpdate, isBreak, index, children }) => {
    const dispatch = useDispatch();
    const [{ dropMonitorId, isOver, canDrop }, drop] = useDrop({
        accept: "break",
        hover:  (item, monitor) => {
            if (!monitor.canDrop()) return;
            if (isBreak) return;

            return scheduleUpdate(() => dispatch(EventDirectorActions.moveBreak({
                event_id: item.eventId,
                sourceIndex: item.index,
                targetPosition: position
            })));
        },
        canDrop: (item) => !heatsStarted && (!isBreak || (item.index === index)),
        drop: item => {
            if (item.position !== position) {
                dispatch(EventDirectorActions.saveSchedule({ event_id: item.eventId }));
            }
            return { dropped: true };
        },
        collect: monitor => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop(),
            dropMonitorId: monitor.getHandlerId()
        })
    });

    return <Box ref={drop} className={classnames("drop-row", { isOver: isOver && canDrop, canDrop, "cannot-drop": !canDrop })} data-drop-monitor-id={dropMonitorId}>{children}</Box>;
};

const ScheduleRow = ({ row, eventId, doEditBreak, scheduleUpdate, endUpdate, podiumsSize, divisionsList, divisionsIds, getSelectedHeats, isHeatSelected, selectHeat, selectedHeats, clearSelectedHeats }) =>
    <DroppableRow position={row.position} index={row.breakIndex} heatsStarted={row.heatsStarted} scheduleUpdate={scheduleUpdate} isBreak={!!row.break}>
        <DraggableRow
            eventId={eventId}
            index={row.breakIndex}
            blockDate={row.break?.get("date")}
            position={row.position}
            heatsStarted={row.heatsStarted}
            endUpdate={endUpdate}
            doEditBreak={doEditBreak}
            isBreak={!!row.break}
        >
            {row.break ?
                <BreakContent blockDate={row.break.get("date")} heatsStarted={row.heatsStarted}/> :

                (row.position < podiumsSize &&
                <>
                    <div className="time">
                        {row.scheduledTime ? dayjs(row.scheduledTime).format("HH:mm") : <span>&nbsp;</span>}
                    </div>

                    {row.heats.map(heat =>
                        <DragDropCell
                            key={`cell-${heat.podium}-${row.position}`}
                            divisionIndicator={divisionsList?.[divisionsIds[heat.heat?.get("division")]]}
                            selectHeat={selectHeat}
                            getSelectedHeats={getSelectedHeats}
                            selected={isHeatSelected(selectedHeats, { podium: heat.podium, position: row.position })}
                            clearSelectedHeats={clearSelectedHeats}
                            eventId={eventId}
                            heatId={heat.heat?.get("id")}
                            heatStartTime={heat.heat?.get("start_time")}
                            heatEndTime={heat.heat?.get("end_time")}
                            heatDivision={heat.heat?.get("division")}
                            heatRound={heat.heat?.get("round")}
                            heatNumber={heat.heat?.get("number")}
                            heatType={heat.heat?.get("type")}
                            heatsStarted={row.heatsStarted}
                            position={row.position}
                            podium={heat.podium}
                            scheduleUpdate={scheduleUpdate}
                            endUpdate={endUpdate}/>
                    )}
                </>)
            }
        </DraggableRow>
    </DroppableRow>;

const EditScheduleContainer = ({ dragStarted, scheduleHeight, innerRef, children }) => {
    const { monitor } = useDragDropManager();

    const isDragging = !!monitor.getItem();

    return (
        <div
            ref={innerRef}
            style={{ height: dragStarted ? scheduleHeight : "auto" }}
            className={classnames("edit-schedule", { "drag-in-progress": isDragging })}
        >
            {children}
        </div>
    );
};

@connect(({ events }, { match: { params: { id: eventId } } }) => ({
    dragStarted: !!events.get("stateBeforeDrag"),
    event: events.getIn(["mini", parseInt(eventId)], Immutable.Map()),
    schedule: events.getIn(["schedules", eventId], Immutable.Map())
        .set("podiums", events.getIn(["schedules", eventId, "podiums"], Immutable.Map()).map(ids => ids.map(id => events.getIn(["heats", eventId, id])))),
    scheduleLoaded: events.getIn(["schedules", eventId, "podiums"])
}))
export class DnDEditSchedule extends Component {
    state = { scheduleHeight: undefined };

    componentDidMount = () => {
        const { location, dispatch, match: { params: { id } } } = this.props,
            blockSelector = location.hash ? `, .${location.hash.slice(1)}` : "";

        dispatch(EventActions.getSchedule(id));
        setTimeout(() => {
            const node = Immutable.List(document.querySelectorAll(`.live${blockSelector}`)).last();
            node && node.scrollIntoView && node.scrollIntoView({ block: "start", inline: "nearest", behavior: "smooth" });
        }, 300);
    };

    componentDidUpdate = (prevProps) => {
        if (prevProps.schedule !== this.props.schedule) {
            this.setState({ scheduleHeight: this.scheduleElement.clientHeight });
        }
    };

    render = () => {
        const { event, schedule: fullSchedule, scheduleLoaded, dragStarted, match: { params: { id: eventId } } } = this.props,
            { scheduleHeight } = this.state,
            schedule = fullSchedule.get("podiums"),
            breaks = fullSchedule.get("breaks", Immutable.List()),
            podiumsSize = schedule.map(podium => podium.size).max(),
            scheduleSize = Immutable.List([podiumsSize, ...breaks.map(b => b.get("position") + 1)]).max(),
            podiums = schedule.keySeq(),
            divisionsIds = {},
            divisions = event.get("event_divisions")?.valueSeq().toArray().map(e => {
                const name = e.get("division_name");
                const id = e.get("division_id");
                divisionsIds[name] = id;
                return { division: { id: id, name: name } };
            }),
            divisionsList = createDivisionList(divisions);

        return (
            <MultiDnD>
                <EditScheduleContainer innerRef={(el) => { this.scheduleElement = el; }} dragStarted={dragStarted} scheduleHeight={scheduleHeight}>
                    {(event.isEmpty()) ?
                        <>
                            <EditScheduleSkeleton />
                        </> :
                        (scheduleLoaded) ?
                            <ScheduleLoaded
                                eventId={eventId}
                                event={event}
                                podiums={podiums}
                                scheduleSize={scheduleSize}
                                schedule={schedule}
                                fullSchedule={fullSchedule}
                                breaks={breaks}
                                podiumsSize={podiumsSize}
                                divisionsList={divisionsList}
                                divisionsIds={divisionsIds}
                            /> :
                            <>
                                <Heading title="Schedule" borderless={true} />
                                <EmptyState
                                    icon={<IconEmptySchedule/>}
                                    text="Once you've created the draw, head back here to organise the event schedule." />
                            </>
                    }
                    <CustomDragLayer/>
                </EditScheduleContainer>
            </MultiDnD>
        );
    };
}
