import { makeStyles } from "components/providers/makeStyles";
import { Typography } from "@mui/material";

import { T } from "../../util/t";

const useHeaderStyles = makeStyles(theme => ({
    title: {
        marginBottom: theme.spacing(2),
    }
}));
export const JudgingAthleteModalHeader = ({ name, team, bib, jersey, ride, runBased }) => {
    const classes = useHeaderStyles();

    return (
        <header>
            <Typography variant="h6" className={classes.title}>
                {runBased ?
                    <T number={ride + 1}>run_number</T> :
                    <T number={ride + 1}>ride_title</T>}
            </Typography>

            <JudgingCompetitorIdentifier name={name} team={team} bib={bib} jersey={jersey}/>
        </header>
    );
};

const useStyles = makeStyles(theme => ({
    athlete: {
        display: "flex",
        alignItems: "center"
    },
    identifier: {
        marginRight: theme.spacing(2),
        textAlign: "center"
    }
}));
export const JudgingCompetitorIdentifier = ({ name, team, bib, jersey }) => {
    const classes = useStyles();

    if (!(jersey || bib || name)) return null;

    return (
        <section className={classes.athlete}>
            {(jersey || bib) &&
            <section className={classes.identifier}>
                {jersey && <JerseyChip jersey={jersey}/>}
                {bib && <Typography className="bib" marginTop={!!jersey && 1} variant="label2" component="div">{bib}</Typography>}
            </section>}

            {name &&
            <section>
                <Typography variant="subtitle3">{name}</Typography>
                {team && <Typography variant="body2" color="textSecondary">{team}</Typography>}
            </section>}
        </section>
    );
};

const useJerseyStyles = makeStyles((theme, { jersey }) => ({
    chip: {
        display: "block",
        backgroundColor: theme.palette.jerseys[jersey].main,
        color: theme.palette.jerseys[jersey].contrast,
        textTransform: "uppercase",
        borderRadius: theme.spacing(1),
        padding: theme.spacing(0.25, 1)
    }
}));
export const JerseyChip = ({ jersey }) => {
    const classes = useJerseyStyles({ jersey });

    return <Typography className={classes.chip} variant="label2"><T>{jersey}</T></Typography>;
};
