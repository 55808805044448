import { useState } from "react";
import {
    Button, Divider,
    Drawer,
    Hidden,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Stack,
    Typography
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";

import { nSpaces, snakeToCamel } from "../../../utils/utils";
import { HidePrinting, ShowPrinting } from "../../util/printing";
import { T } from "../../util/t";
import { HeadingNav } from "../../layout/navigation/HeadingNav";
import { Add, ArrowDropDown, Close, MoreHoriz, Settings } from "@mui/icons-material";
import { useHeadingStyles } from "../../layout/page/Heading";
import classnames from "classnames";
import { makeStyles } from "../../providers/makeStyles";
import { Series } from "models/Series";

const useStyles = makeStyles({
    menu: {
        cursor: "pointer"
    }
});

export const SeriesSelector = ({ series, allSeries, selectSeries, actions = [], organisation, selectedDivision, className }) => {
    const classes = useHeadingStyles({ borderless: true });
    const seriesSpecificClasses = useStyles();
    const seriesObject = series && Series(snakeToCamel(series));
    const isRankingsSeries = seriesObject?.isRankingsSeries();
    const isMembershipSeries = seriesObject?.isMembershipSeries();

    const [menu, setMenu] = useState(null),
        [drawerOpen, setDrawerOpen] = useState(false),
        openMenu = (e) => setMenu(e.currentTarget),
        closeMenu = () => setMenu(null),
        clickMenuItem = series => {
            selectSeries(series);
            closeMenu();
        },
        openDrawer = () => setDrawerOpen(true),
        closeDrawer = () => setDrawerOpen(false),
        clickDrawerItem = (onClick) => () => {
            onClick && onClick();
            closeDrawer();
        },
        validActions = actions.filter(a => a),
        location = useLocation(),
        seriesSettingsProps = series ? { component: Link, to: { pathname: `/series/${series.id}/edit`, state: { from: location } } } : {};

    return (
        <div className={classnames(classes.heading, className)}>
            <ShowPrinting>
                {organisation?.name}
            </ShowPrinting>
            <div className={classnames(classes.flexBox, classes.headingTitle, "heading-title")}>
                <Typography variant="h3" component="h1" className={classnames(classes.flexBox, classes.title, seriesSpecificClasses.menu)} onClick={openMenu}>
                    {series
                        ? <>
                            <HidePrinting>
                                {series.name}
                            </HidePrinting>
                            <ShowPrinting>
                                <T>Rankings</T>{` / ${series.name} / ${selectedDivision?.name}`}
                            </ShowPrinting>
                        </>
                        : <span className="fake">{nSpaces(20)}</span>}
                    <ArrowDropDown/>
                </Typography>
                <Stack direction="row" spacing={1} className={classnames(classes.headingActions, "heading-actions")}>
                    <Button variant="outlined" {...seriesSettingsProps} startIcon={<Settings/>}>
                        <T>Series settings</T>
                    </Button>

                    <Hidden mdDown>
                        {validActions.map((action, i) =>
                            <Button key={i} variant="outlined" {...action.props} startIcon={action.icon}>
                                <T>{action.label}</T>
                            </Button>
                        )}
                    </Hidden>

                    <Hidden mdUp>
                        <Button className="more" onClick={openDrawer} variant="outlined">
                            <MoreHoriz/>
                        </Button>

                        <Drawer anchor="bottom" open={drawerOpen} onClose={closeDrawer}>
                            <List>
                                {validActions.map((action, i) =>
                                    <ListItem key={i} button {...action.props} onClick={clickDrawerItem(action.props.onClick)}>
                                        <ListItemIcon>{action.icon}</ListItemIcon>
                                        <ListItemText primary={<T>{action.label}</T>}/>
                                    </ListItem>
                                )}

                                <ListItem button onClick={closeDrawer}>
                                    <ListItemIcon><Close/></ListItemIcon>
                                    <ListItemText primary={<T>Close</T>}/>
                                </ListItem>
                            </List>
                        </Drawer>
                    </Hidden>
                </Stack>
            </div>

            {(allSeries && series) &&
            <Menu anchorEl={menu} open={!!menu} onClose={closeMenu}>
                <Typography variant="label2" color="textSecondary" component="li" textTransform="uppercase" px={1.5} py={1}><T>select series</T></Typography>

                {allSeries.map((series, i )=>
                    <MenuItem key={i} onClick={() => clickMenuItem(series)}>
                        {series.name}
                    </MenuItem>
                )}

                <Divider/>

                <MenuItem onClick={closeMenu} component={Link} to={{ pathname: "/series/new", state: { from: location } }}>
                    <Add/>
                    <T>Create new series</T>
                </MenuItem>
            </Menu>}

            <HeadingNav className={classes.headingNav} links={[
                !(isMembershipSeries && !isRankingsSeries) && { label: "Rankings", to: `/director/rankings${series ? `?series=${encodeURIComponent(series.id)}` : ""}` },
                !(isRankingsSeries && !isMembershipSeries) && { label: "Members", to: `/series/${(series || {}).id}/members`, disabled: !series }
            ]}/>
        </div>
    );
};
