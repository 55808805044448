import { makeStyles } from "components/providers/makeStyles";
import { useTranslate } from "components/providers/LocalizationProvider";
import { Autocomplete, CircularProgress, Paper, TextField, Typography } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { Option } from "./Option";
import { useState } from "react";
import { Clear } from "@mui/icons-material";

export const filter = createFilterOptions({ trim: true });
export const useLoadingText = () => useTranslate("Loading…");

export const SearchSelect = ({ field, input, hasError, filterOptions = filter, ListboxComponent, ...rest }) => {
    const isOptionEqualToValue = (option, { value }) => option.value?.toString() === value?.toString();
    const [selectedOption, setSelectedOption] = useState();

    const noOptionsText = useTranslate(field.noOptions?.message || "No options", field.noOptions?.messageValues);
    const loadingText = useLoadingText();
    const placeholderText = useTranslate(field.placeholder);

    const onKeyDown = e => {
        if (e.key === "Enter" && !selectedOption) {
            e.defaultMuiPrevented = true;
            e.preventDefault();
        }
    };

    return (
        <Autocomplete
            autoHighlight
            openOnFocus
            options={field.options}
            isOptionEqualToValue={isOptionEqualToValue}
            value={input.value}
            onChange={(e, value) => input.onChange(value)}
            loading={field.loading}
            clearIcon={field.loading ? <CircularProgress color="inherit" size={20} /> : <Clear />}
            loadingText={loadingText}
            noOptionsText={noOptionsText}
            PaperComponent={PaperComponent}
            ListboxComponent={ListboxComponent}
            onKeyDown={onKeyDown}
            onHighlightChange={(e, option) => setSelectedOption(option)}
            renderInput={params =>
                <TextField {...params} error={hasError} inputProps={{ ...params.inputProps, ...field.inputProps }} placeholder={placeholderText}/>
            }
            filterOptions={filterOptions}
            disabled={field.disabled}
            getOptionDisabled={option => !!option.disabled}
            renderOption={Option}
            {...field.autocompleteProps}
            {...rest}
        />
    );
};

const usePaperStyles = makeStyles(theme => ({
    label: {
        margin: theme.spacing(2, 2, 0),
        textTransform: "uppercase",
        opacity: 0.5
    }
}));
export const PaperComponent = ({ children, headingText = "Type or select", ...props }) => {
    const classes = usePaperStyles();
    const heading = useTranslate(headingText);

    return (
        <Paper elevation={2} {...props}>
            <Typography className={classes.label} variant="label2" color="textSecondary" component="div">
                {heading}
            </Typography>
            {children}
        </Paper>
    );
};
