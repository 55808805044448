import { useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    ListItem,
    ListItemIcon,
    ListItemText
} from "@mui/material";
import { Check, Refresh, Storage } from "@mui/icons-material";

import { redraw } from "../../../actions/eventDivision";

import { LoadingButton } from "../../actions/loadingButton";
import { useDialogState } from "../../../hooks/useDialogState";
import { T } from "../../util/t";

export const RedrawDialog = ({ inList, drawn, eventId, eventDivisionId, postRedraw }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [dialogOpen, openDialog, closeDialog] = useDialogState();
    const [reseed, setReseed] = useState(true);
    const toggleReseed = useCallback(() => setReseed(!reseed), [reseed]);

    const icon = drawn ? <Refresh/> : <Storage/>;
    const text = drawn ? <T>Redraw heats</T> : <T>Draw heats</T>;

    const onRedraw = useCallback(() => {
        return dispatch(redraw(eventDivisionId, reseed))
            .then(() => {
                closeDialog();
                postRedraw && postRedraw();
            })
            .then(() => history.push(`/events/${eventId}/divisions/${eventDivisionId}/draw`));
    }, [reseed]);

    return (
        <>
            {inList ?
                <ListItem button onClick={openDialog}>
                    <ListItemIcon>
                        {icon}
                    </ListItemIcon>
                    <ListItemText primary={text}/>
                </ListItem> :
                <Button onClick={openDialog} variant="outlined" startIcon={icon}>
                    {text}
                </Button>
            }

            <Dialog open={dialogOpen}
                    onClose={closeDialog}
                    aria-labelledby="redraw-dialog-title"
                    aria-describedby="redraw-dialog-description">
                <RedrawDialogContent
                    drawn={drawn}
                    reseed={reseed}
                    toggleReseed={toggleReseed}
                    onRedraw={onRedraw}
                    closeDialog={closeDialog}
                />
            </Dialog>
        </>
    );
};

export const RedrawDialogContent = ({ drawn, reseed, toggleReseed, onRedraw, closeDialog }) => {
    const { current: isDrawn } = useRef(drawn);

    return (
        <>
            <DialogTitle id="redraw-dialog-title">
                {isDrawn ?
                    <T>Are you sure you want to redraw?</T> :
                    <T>Are you sure you want to draw the heats?</T>
                }
            </DialogTitle>
            <DialogContent id="redraw-dialog-description">
                <p>
                    {isDrawn ?
                        <T>Changes previously made to rounds that have not started will be lost.</T> :
                        <T>The heat draw will be created but entries will still be open until you close them for the
                        event.</T>
                    }
                </p>
                {isDrawn &&
            <div className="formField checkbox">
                <div className="checkbox-input">
                    <input id="reseed" type="checkbox" checked={reseed} onChange={toggleReseed}/>
                    <label className="checkbox" htmlFor="reseed"> <T>Reseed the heats</T></label>
                    {reseed && <Check />}
                </div>
            </div>}
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={closeDialog}>
                    <T>Cancel</T>
                </Button>
                <LoadingButton variant="contained" color="primary" action={onRedraw}>
                    {isDrawn ? <T>Yes, redraw</T> : <T>Yes, draw</T>}
                </LoadingButton>
            </DialogActions>
        </>);
};
