import PropTypes from "prop-types";
import classnames from "classnames";
import { Button } from "@mui/material";
import { IconButton } from "@mui/material";
import { CircularProgress } from "@mui/material";

import { AsyncAction } from "./asyncAction";

export class LoadingIconButton extends AsyncAction {
    static propTypes = {
        action: PropTypes.func.isRequired,
        title: PropTypes.string,
        className: PropTypes.string,
        Icon: PropTypes.object,
    };

    render = ({ className, buttonClassName, title, label, Icon } = this.props, { disabled } = this.state) =>
        <IconButton className={classnames("loading-icon-button", className, buttonClassName)} aria-label={title} title={title} disabled={disabled} onClick={this.onClick}>
            <Icon />
            {disabled && <CircularProgress className="circular-progress" />}
            {label && <div className="label">{label}</div>}
        </IconButton>;
}

export class LoadingButton extends AsyncAction {
    static propTypes = {
        action: PropTypes.func.isRequired,
        className: PropTypes.string
    };

    render = ({ className, children, action, disabled: propsDisabled, submitting, ...rest } = this.props, { disabled } = this.state) => {
        const isDisabled = propsDisabled || disabled;
        const inProgress = (disabled || submitting);
        return (
            <Button className={classnames("loading-button", className, { hideText: inProgress })} {...rest} disabled={isDisabled} onClick={this.onClick}>
                {children}
                {inProgress && <CircularProgress color="secondary" className="circular-progress" />}
            </Button>
        );
    };
}
