import { gql } from "@apollo/client";
import { HeatFragment, ScheduledHeatFragment } from "./heat";
import { DivisionFragment } from "./division";

const EventBasicInfoFragment = gql`
  fragment EventBasicInfo on Event {
    id
    name
    status
    date
    daysWindow
    hideEntries
    organisation {
      id
      name
      contactEmail
    }
    series {
      id
      name
      organisation {
        shortName
      }
      rankingsDivisions {
        id
      }
    }
  }
`;

const EventBaseFragment = gql`
  fragment EventBase on Event {
    ...EventBasicInfo
    hideFinals
    hideScheduledTime
    hideEntries
    priorityEnabled
    organisationId
    # youtubeVideoId
    organisation {
      id
      name
      shortName
      contactEmail
      sportType
    }
    series {
      id
      name
      exclusive
      organisationId
      pointAllocationId
      organisation {
        shortName
        name
      }
    }
    eventDivisions {
      id
      eventDivisionId
      status
      entryCount
      entryLimit {
        id
        limit
      }
      formatDefinition {
        isTeams
        teamConfig {
          athletesPerTeam
        }
      }
      heatConfig {
        hasPriority
      }
      division {
        ...Division
      }
      eventDivisions {
        id
        formatDefinition {
          isTeams
        }
        division {
          ...Division
        }
      }
    }
    paymentOptions {
      currency
      defaultDivisionPrice
      divisions {
        id
        price
      }
      extraDivisionPrice
      familyTotal
      extras {
        uuid
        name
        price
        options
      }
    }
    registrationOptions {
      waitlisted
      notes
      termsAndConditionsLink
      termsOwner
      divisionLimit
      teamRequired
      divisions {
        id
        maxAge
        maxBy
        minAge
        minBy
      }
      captureProperties {
        uuid
        label
        type
        level
        required
        options
        docusealTemplateSlug
      }
    }
    captureFields {
      uuid
      label
      type
      level
      required
      options
      docusealTemplateSlug
    }
    config {
      teamLeaderboard {
        pointsPerPlace
      }
      disableSchedule
    }
  }
  ${EventBasicInfoFragment}
  ${DivisionFragment}
`;

const EventScheduleFragment = gql`
  fragment EventSchedule on Event {
    id
    currentScheduleIndex
    fullSchedule {
      heatsIntervalSeconds
      breaks {
        date
        position
      }
      podiums {
        name
        heats {
          ...ScheduledHeat
          eventDivision {
            division {
              name
            }
          }
        }
      }
    }
  }
  ${ScheduledHeatFragment}
`;

export const GET_EVENT = gql`
  query getEvent($id: ID!) {
    event(id: $id) {
      ...EventBase
      ...EventSchedule
    }
  }
  ${EventBaseFragment}
  ${EventScheduleFragment}
`;

export const GET_EVENT_BASE = gql`
  query getEventBase($id: ID!) {
    event(id: $id) {
      ...EventBase
    }
  }
  ${EventBaseFragment}
`;

export const GET_EVENT_SCHEDULE = gql`
  query getEventSchedule($id: ID!) {
    event(id: $id) {
      ...EventSchedule
    }
  }
  ${EventScheduleFragment}
`;

export const GET_CURRENT_HEATS = gql`
  query getCurrentHeats($id: ID!) {
    event(id: $id) {
      id
      currentScheduleIndex
      currentHeats {
        ...Heat
      }
    }
  }
  ${HeatFragment}
`;

export const GET_EVENT_TEAM_LEADERBOARD = gql`
  query getEventTeamLeaderboard($id: ID!) {
    event(id: $id) {
      id
      teamLeaderboard {
        id
        config {
          pointsPerPlace
        }
        result {
          place
          teamName
          total
          placeCounts
        }
      }
    }
  }
`;

export const SEARCH_EVENT_COMPETITORS = gql`
  query($eventId: ID!, $query: String!) {
    eventCompetitors(eventId: $eventId, query: $query) {
      ...on Competitor {
        id
        heatId
        athlete {
          id
          name
          image
        }
        heat {
          ...ScheduledHeat
        }
      }
      ...on Entry {
        id
        eventDivisionId
        seed
        athlete {
          id
          name
          image
        }
        eventDivision {
          id
          division {
            id
            name
          }
          formatDefinition {
            hideSeeds
            entriesRandomized
          }
        }
      }
    }
  }
  ${ScheduledHeatFragment}
`;

export const UPDATE_EVENT_CONFIG = gql`
  mutation updateEventConfig($eventId: ID!, $config: EventConfigInput!) {
    updateEventConfig(input: {eventId: $eventId, config: $config}) {
      event {
        id
        config {
          teamLeaderboard {
            pointsPerPlace
          }
        }
      }
    }
  }
`;

export const CREATE_EVENT = gql`
  mutation createEvent($event: EventInput!) {
    createEvent(input: {event: $event}) {
      event {
        id
        name
        status
        date
        daysWindow
        organisationId
        eventDivisions {
          id
          division {
            id
            name
          }
        }
      }
    }
  }
`;

export const CREATE_ENTRIES = gql`
  mutation createEntries($eventId: ID!, $entries: [EntryInput!]!) {
    createEntries(input: {eventId: $eventId, entries: $entries}) {
      entries {
        id
      }
    }
  }
`;

export const COPY_EVENT = gql`
  mutation copyEvent($event: EventInput!, $eventId: ID!) {
    copyEvent(input: {event: $event, eventId: $eventId}) {
      event {
        id
        name
        status
        date
        daysWindow
        organisationId
        eventDivisions {
          id
          division {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_EVENT_BASIC_INFO = gql`
  query getEventBasicInfo($id: ID!) {
    event(id: $id) {
      ...EventBasicInfo
    }
  }
  ${EventBasicInfoFragment}
`;

export const GET_EVENT_DATA_FOR_COPY = gql`
  query getEventDataForCopy($id: ID!) {
    event(id: $id) {
      id
      seedFromSeriesId
      series {
        id
        name
      }
      eventDivisions {
        division {
          id
          name
        }
        template {
          id
        }
        entryLimit {
          limit
        }
        order
      }
    }
  }
`;

export const GET_EVENT_ACTIVE_ENTRIES_USERS = gql`
  query getEventEntries($id: ID!) {
    event(id: $id) {
      id
      activeEntriesUsers {
        id
      }
    }
  }
`;

export const GET_EVENT_SENT_EMAILS = gql`
  query getEventSentEmails($id: ID!) {
    event(id: $id) {
      id
      sentEmails {
        subject
        body
        createdAt
      }
    }
  }
`;

export const SEND_EVENT_EMAIL = gql`
  mutation sendEventEmail($eventId: ID!, $subject: String!, $message: String!) {
    sendEventEmail(input: {eventId: $eventId, subject: $subject, message: $message }) {
      event {
        id
        sentEmails {
          subject
          body
          createdAt
        }
      }

    }
  }
`;
