import { Button, Drawer, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Close, MoreHoriz } from "@mui/icons-material";
import { Fragment } from "react";
import { T } from "../../util/t";

export const EditHeatToolbarSecondaryMenu = ({ items, isOpen, openDrawer, closeDrawer }) => (
    <>
        <Button className="more" onClick={openDrawer} variant="outlined">
            <MoreHoriz />
        </Button>

        <Drawer anchor="bottom" open={isOpen} onClose={closeDrawer}>
            <List>
                {items.map((item, index) => <Fragment key={index}>{item}</Fragment>)}
                <ListItem button onClick={closeDrawer}>
                    <ListItemIcon><Close/></ListItemIcon>
                    <ListItemText primary={<T>Close</T>}/>
                </ListItem>
            </List>
        </Drawer>
    </>
);
