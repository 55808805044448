import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useCurrentUser } from "../CurrentUserProvider";

export const RealtimeContext = createContext({});
RealtimeContext.displayName = "Ably";

export const useRealtimeChannel = (channel, event, handler) => useContext(RealtimeContext).useChannel?.(channel, event, handler) || {};
export const useBindToChannelEvent = (channelName, event, handler) => {
    const callback = useCallback(message => {
        handler(message.data);
    }, [handler]);
    const { channel } = useRealtimeChannel(channelName, event, callback);
    useEffect(() => () => {
        if (channel) {
            setTimeout(async () => {
                if ((channel.state === "attaching" || channel.state === "attached") && channel.subscriptions.any.length === 0 && channel.subscriptions.anyOnce.length === 0 && Object.keys(channel.subscriptions.events).length === 0 && Object.keys(channel.subscriptions.eventsOnce).length === 0) {
                    await channel.detach();
                }
            }, 2500);
        }
    }, [channel, event, callback]);
};

export const RealtimeProvider = ({ children }) => {
    const [Ably, setAbly] = useState();
    const [client, setClient] = useState(null);
    const [module, setModule] = useState({});
    const { loaded, currentUser } = useCurrentUser();

    useEffect(() => {
        import(/* webpackChunkName: "ably" */ "ably").then(ably => setAbly(ably));
        import(/* webpackChunkName: "ably" */ "ably/react").then(module => setModule(module));
    }, []);

    useEffect(() => {
        if (Ably && loaded) {
            setClient(prevClient => {
                prevClient?.close();
                return new Ably.Realtime({
                    authUrl: "/api/realtime_auth",
                    authMethod: "POST"
                });
            });
        }
    }, [loaded, currentUser, Ably]);

    const { useAbly, useChannel, useChannelStateListener, useConnectionStateListener, usePresence } = module;
    return (client && module) ?
        <module.AblyProvider client={client}>
            <RealtimeContext.Provider value={{ useAbly, useChannel, useChannelStateListener, useConnectionStateListener, usePresence }}>
                {children}
            </RealtimeContext.Provider>
        </module.AblyProvider> :
        children;
};
